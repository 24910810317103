import * as React from 'react';
import { useSnackbar } from 'notistack';
import { toArray, downloadUri } from '../utils/Utils';
import { HoursTimespan } from '../components/HoursTimespanSelector';
import { dateToISOWithReducedPrecision } from '../utils/DateUtils';
import moment from 'moment';
import { useFetchApi } from '../utils/UseFetchApi';
import { Link } from 'react-router-dom';

export enum JobState {
    Unsubmitted = "unsubmitted",
    Active = "active",
    Completed = "completed"
}

export enum JobOutcome {
    Success = "success",
    Failed = "failed",
    Cancelled = "cancelled",
}

export interface IInput {
    type: string,
    id: string
}

export interface IOutput {
    type: string,
    id: string
}

export interface IJobOptions {
    processingEngines: number;
    merge?: boolean;
    inputSRS?:number;
    outputSRS?:number
}

export interface ICostEstimationParameters {
    gigaPixels?: number,
    megaPoints?: number
}

export interface ICostEstimation extends ICostEstimationParameters {
    estimatedCost?: number
}

export interface IPublicExecutionInformation {
    startedDateTime?: string,
    endedDateTime?: string,
    submissionDateTime?: string,
    exitCode?: number,
    estimatedUnits?: number
}

export interface IPublicExecutionInformationWithOutcome extends IPublicExecutionInformation {
    outcome?: JobOutcome
}

export interface IJob {
    createdDateTime: string,
    lastModifiedDateTime: string,
    type: string,
    state: JobState,
    name: string,
    costEstimationParameter?: ICostEstimation,
    inputs: IInput[],
    outputs: IOutput[],
    options: IJobOptions,
    iTwinId?: string,
    id: string,
    email: string,
    dataCenter: string,
    executionInformation?: IPublicExecutionInformation
}

export interface IDataCenter {
    location: string,
    dataCenterId: string
}

export interface IPrivateUserDetails {
    ultimateRefId: string,
    ultimateSite: string,
    userId: string,
    email: string
}

export interface IPrivateSubmissionDetails {
    userAgent: string,
    clusterId: string,
    consumptionResourceId?: string
}

export interface IPrivateExecutionInformation {
    computeTime?: number
}

export interface IPrivateJobInformation {
    jobUltimateRefId: string,
    clientId: string,
    clientTier: string
}

export interface IJobDetailed {
    name: string,
    id: string,
    type: string,
    iTwinId?: string,
    createdDateTime: string,
    lastModifiedDateTime: string,
    email: string,
    costEstimation?: ICostEstimation,
    state: JobState,
    dataCenter: IDataCenter,
    inputs?: IInput[],
    outputs?: IOutput[],
    options?: IJobOptions,
    privateUserDetails: IPrivateUserDetails,
    privateSubmissionDetails?: IPrivateSubmissionDetails,
    privateExecutionInformation?: IPrivateExecutionInformation,
    privateJobInformation?: IPrivateJobInformation,
    processingEngines?: number,
    executionInformation?: IPublicExecutionInformationWithOutcome
}

export interface IJobPatch {
    type?: string,
    costEstimationParameters?: ICostEstimationParameters,
    state?: string,
    inputs?: IInput[],
    outputs?: IOutput[],
    options?: IJobOptions
}

export interface IJobResponse {
    job: IJob
}

export interface ITimeSpan {
    startDate?: Date;
    endDate?: Date;
}

//link to job details page
export function JobLink(props: { job: IJobDetailed }) {
    return (
        <Link
            to={{ pathname: `/admin/jobs/${props.job.id}` }}
            style={{ textDecoration: "none" }}
        >
            {props.job.name}
        </Link>
    );
}

export function useJobReport(queryParams?: string, ignoreTimeSpan: boolean = false) {
    const sevenDaysMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const [hoursTimeSpan, setHoursTimeSpan] = React.useState<HoursTimespan>(HoursTimespan.LastWeek);
    const [timespan, setTimespan] = React.useState<ITimeSpan>({
        startDate: ignoreTimeSpan ? undefined : new Date(Date.now() - sevenDaysMilliseconds),
        endDate: undefined
    });
    const fetchApi = useFetchApi<IJobDetailed[]>(getUrl(timespan.startDate, timespan.endDate));

    function getUrl(startDate?: Date, endDate?: Date) {
        const url = new URL("/api/v1/jobs/report", window.location.origin);

        if (queryParams) url.search = queryParams;
        if (startDate) url.searchParams.set('startTime', dateToISOWithReducedPrecision(startDate));
        if (endDate) url.searchParams.set('endTime', dateToISOWithReducedPrecision(endDate));

        return url.href;
    }
    
    function refresh() {
        fetchApi.run();
    }

    function handleTimespanChange(newSpan: ITimeSpan) {
        setTimespan(newSpan);
        fetchApi.run(getUrl(newSpan.startDate, newSpan.endDate));
    }
    
    function handleHoursTimespanChange(newSpan: HoursTimespan) {
        var startDate: Date = new Date(moment().subtract(newSpan as number, 'hours').toDate());
        setHoursTimeSpan(newSpan);
        setTimespan({ startDate });
        fetchApi.run(getUrl(startDate));
    }

    // run once at startup
    React.useEffect(() => {
        fetchApi.run();
    }, []);

    return {
        data: fetchApi.data,
        hasData: fetchApi.hasData,
        refresh,
        isFetching: fetchApi.isFetching,
        fetchCount: fetchApi.fetchCount,
        timespan,
        SetTimespan: handleTimespanChange,
        hoursTimeSpan,
        SetHoursTimespan: handleHoursTimespanChange,
        error: fetchApi.error
    }
}

export function useJobActions() {
    const { enqueueSnackbar } = useSnackbar();
    const terminateApi = useFetchApi<IJob>();
    const downloadApi = useFetchApi<any>();

    function terminate(maybeJobs: IJobDetailed[] | IJobDetailed | IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            var result = window.confirm(`Are you sure you want to kill '${job.name}' id: ${job.id} that belong to ${job.email} ?"`);
            if (!result)
                return;

            enqueueSnackbar(`Killing '${job.name}' id: ${job.id}...`, { variant: "info" });

            terminateApi.run(window.location.origin + "/api/v1/jobs/" + job.id + "/terminate",
                {
                    method: 'POST'
                })
                .then(result =>
                    enqueueSnackbar(`Job '${result?.name}' killed!`, { variant: "success" }))
                .catch(error => {
                    enqueueSnackbar(`Failed to kill '${job.name}' : ${error.toString()}`, { variant: "error" });
                });
        });
    }

    function downloadLogs(maybeJobs: IJobDetailed[] | IJobDetailed | IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            downloadApi.run(window.location.origin + "/api/v1/jobs/" + job.id + "/logsaccess")
                .then(result => {
                    if (!result?.uri) {
                        enqueueSnackbar(`Failed to download logs of '${job.name}'`);
                        return;
                    }
                    downloadUri(result.uri);
                })
                .catch(error => {
                    enqueueSnackbar(`Failed to download '${job.name}' : ${error.toString()}`, { variant: "error" });
                })
        });
    }

    return {
        terminate,
        downloadLogs
    }
}
